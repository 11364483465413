import * as React from "react"
import {Link} from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHashtag } from "@fortawesome/free-solid-svg-icons"

const _ = require('lodash')

const TagCloud = ({ tags }) => {

  if (!tags.group.length) return null

  let tag_list = []
  _.each(tags.group, node => {
    if (_.get(node, 'tag')) {
      tag_list = _.concat(tag_list, node.tag)
    }
  })
  tag_list = _.uniq(tag_list)

  return (
    <div className="tag-cloud meta-item-list">
        <p className="title">タグ</p>
        <ul className="tags">
          {tag_list.map((tag, i) => {
            const tag_uri = '/tag/' + fixedEncodeURI(tag) + '/'
            return (<li key={i}><span className="icon"><FontAwesomeIcon icon={faHashtag}/></span><Link to={tag_uri} itemProp="url">{tag}</Link></li>)
          })}
        </ul>
    </div>
  )
}

function fixedEncodeURI(str) {
  return encodeURI(_.kebabCase(str).replace(/[.]/g, function(c) {
    return '%' + c.charCodeAt(0).toString(16);
  }));
}

export default TagCloud
import * as React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import HighlightPop from 'react-highlight-pop'

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PostMeta from "../components/post-meta"
import Pop from "../components/pop"
import { Disqus } from 'gatsby-plugin-disqus'
import TagCloud from "../components/tag-cloud"
import CategoryList from "../components/category-list"
import Adsense from "../components/adsence"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.mdx

  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next, tags, categories } = data

  const previous_link = (previous) ? "/" + previous.slug : null
  const next_link = (next) ? "/" + next.slug : null

  const post_url = data.site.siteMetadata.siteUrl + "/" + post.slug

  let disqusConfig = {
    url: post_url,
    identifier: post.id,
    title: post.frontmatter.title,
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        type='article'
        url={post_url}
      />
      <HighlightPop
        popoverItems={itemClass => (
          <Pop itemClass={itemClass}/>
        )}
      >

          <div className="post-body">
            <section className="main">
              <article
                className="blog-post"
                id="shareable"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h1 itemProp="headline">{post.frontmatter.title}</h1>
                  <PostMeta date={post.frontmatter.date} categories={post.frontmatter.category} tags={post.frontmatter.tag} />
                </header>
                <div className="content">
                  <MDXRenderer>{post.body}</MDXRenderer>
                </div>
                <hr />
                <footer>
                  <Bio />
                </footer>
                <Disqus config={disqusConfig} />
                </article>
            </section>
            <section className="sidebar">
              <CategoryList categories={categories} />
              <TagCloud tags={tags}/>
              <Adsense />
            </section>
          </div>

      </HighlightPop>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous_link} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next_link} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    mdx(slug: { eq: $id }) {
      id
      slug
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "YYYY/MM/DD")
        category
        tag
      }
    }
    previous:  mdx(slug: { eq: $previousPostId }) {
      slug
      frontmatter {
        title
      }
    }
    next:  mdx(slug: { eq: $nextPostId }) {
      slug
      frontmatter {
        title
      }
    }
    tags: allMdx {
        group(field: frontmatter___tag) {
            tag: fieldValue
        }
    }
    categories: allMdx {
        group(field: frontmatter___category) {
            category: fieldValue
        }
    }
  
  }
`

import * as React from "react"
import {Link} from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder} from "@fortawesome/free-regular-svg-icons"
const _ = require('lodash')

const CategoryList = ({ categories }) => {

  if (!categories.group.length) return null
  return (
    <div className="category-list">
      <p className="title">カテゴリー</p>
      <ul className="category">
        {categories.group.map((cat, i) => {
          if (_.get(cat, 'category')) {
            const uri = '/category/' + fixedEncodeURI(cat.category) + '/'
            return (<li key={i}><span className="icon"><FontAwesomeIcon icon={faFolder} /></span><Link to={uri} itemProp="url">{cat.category}</Link></li>)
          } else {
            return null
          }
        })}
      </ul>
    </div>
  )
}

function fixedEncodeURI(str) {
  return encodeURI(_.kebabCase(str).replace(/[.]/g, function(c) {
    return '%' + c.charCodeAt(0).toString(16);
  }));
}

export default CategoryList